.expertize-container {
    margin: 2rem 0rem;
}

.expertize-container h3 {
    text-align: center;
}

.expertize-container-row {
    position: relative;
    width: 100%;
    height: 95vh;

    margin: 2rem 0rem;

    background-color: #404040;

}

.expertize-container-row img{
    width: 100%;
    height: 95vh;


    transition: all .5s ease;
}

.expertize-container-row:hover img{
   opacity: 0.4;
}

#expertize-architecture-title{
    position: absolute;
    top: 10%;

    padding-left: 4rem;
    color: #fff;

    font-size: 3rem;
    font-weight: bold;
}

.expertize-container-row
#expertize-architecture-body{
    position: absolute;
    top: 22%;
    text-align: justify;
    font-size: small;

    padding: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
    color: #fff;

    opacity: 0;
    transition: all .5s ease;
}

.expertize-container-row:hover
#expertize-architecture-body,
.expertize-container-row:active
#expertize-architecture-body{
    opacity: 1;
}

@keyframes slide {
    0% {
        margin-left: 0;
    }
  
    100% {
        margin-left: -10%
    }
}


@media only screen and (max-width: 640px) {

    .expertize-container {
        overflow-x: hidden;
    }
    
    .expertize-container-row img,
    .expertize-container-row {
       height: 100vh;
    
    }
    

    #expertize-architecture-title{
        padding-left: 0rem;
        text-align: center;
        width: 100%;
    }

    .expertize-container-row img{
        width: 110%;
        animation-name: slide;
        animation-duration: 3s;
    
        margin-left: -10%;
 
        min-width: 100%;
    }

}