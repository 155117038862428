.studio-section-profile, 
.studio-section-philosophy, 
.studio-section-people {
    padding-top: 5rem;
    padding-bottom: 2rem;
}

.studio-section-profile h4,
.studio-section-philosophy h4,
.studio-section-people h4 {
    text-align: center;
    margin-bottom: 2rem;
}

.studio-section-profile p,
.studio-section-philosophy p,
.studio-section-people p {
    text-align: justify;
    margin-right: 4rem;
    margin-left: 4rem;

    font-size: small;
}

.studio-animation {
    animation: fadeInUp; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1.5s; 
    animation-delay: .5s;
}