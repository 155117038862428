.project-content-header {
    width: 100%;
    position: relative;
    overflow-x: hidden;
}

.project-content-header img {
    width: 100vw;
    height: 100vh;
}

.project-content-header p {
    position: absolute;
    top: 70%;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: .3rem;
    animation: fadeInUp;
    /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1.5s;
    text-transform: uppercase;
}

.project-content-paragraph {
    margin: 2rem 4rem;
}

.project-content-one-img,
.project-content-one-img img {
    width: 100%;
    height: 100vh;

    margin: 1rem 0rem;
}

.project-content-two-imgs {
    display: flex;
    margin: 1rem 0rem;

    justify-content: space-between;
    height: 100vh;
    width: 100%;

    margin-top: 2rem;
}

.project-content-two-imgs img {
    width: 49.5%;
    height: 100vh;
}


@media only screen and (max-width: 480px) {

    .project-content-one-img {
        margin: 0rem;
        width: 100%;
        height: fit-content;
    }
    .project-content-one-img img {
        width: 100%;
        height: 50vh;
        margin-top: 2rem;
    }

    .project-content-two-imgs {
        display: flex;
        flex-direction: column;
        height: fit-content;
        margin: 0rem;
    }

    .project-content-two-imgs img {
        width: 100%;
        margin-top: 2rem;
        height: 100vh;
    }

    .project-content-header p {
        font-size: 1.5rem;
    }
    

}