.onsite-container {
    display: flex;
    justify-content: space-evenly;

    padding: 2rem;
    padding-bottom: 5rem;
}

.onsite-container-map {
    width: 45%;
}

.onsite-container-map h5 {
    font-size: medium;

    margin-top: 1rem;
}

.onsite-container-map p {
    font-size: small;
}

@media only screen and (max-width: 640px) {
    .onsite-container {
        display: block;
    }

    .onsite-container-map {
        width: 90%;

        margin-left: auto;
        margin-right: auto;
        padding-bottom: 2rem;
    }
}