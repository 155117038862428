.projects-header-container {
    width: 100%;
    position: relative;

    overflow-x: hidden;
}

.projects-header-container img {
    width: 110vw;
    height: 100vh;

    animation-name: slide;
    animation-duration: 16s;

    margin-left: -10%;

    
}

.projects-header-container p {
    position: absolute;
    top: 70%;

    width: 100%;
    text-align: center;

    color: #fff;
    font-size: 3rem;
    font-weight: bold;

    letter-spacing: .3rem;

    animation: fadeInUp; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1.5s; 
}

@keyframes slide {
    0% {
        margin-left: 0;
    }
  
    100% {
        margin-left: -10%
    }
}

@media only screen and (max-width: 640px) {
    .projects-header-container p {
        top: 80%;
    }

    .projects-header-container img {
        width: 150vw;        
    }
}