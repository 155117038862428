.contact-us-container{
    color: #404040;
    text-align: center;
    padding-top: 5rem;
    padding-bottom: 5rem;

}

.contact-us-form {
    margin: 1rem 2rem;
    padding: 2rem;
    

}

.contact-us-form-row {
    display: flex;

    justify-content: space-between;
}

.contact-us-form-col {
    width: 50%;
}

.contact-us-form input, 
.contact-us-form textarea{
    border: 1px solid #404040;
    outline: none;
    padding: 1rem;
    margin-bottom: 2rem;
    margin-top: 0.5rem;
    width: 80%;
}

.contact-us-form select {
    width: 80%;
}

.contact-us-form textarea {
    min-height: 16rem;
}

.contact-us-form input:focus, 
.contact-us-form textarea:focus{
    font-style: italic;
}

.form-send-button button {
    background: rgba(34, 34, 34, 1);
    border-radius: 5px;
    padding: 0.5rem 1rem;
    color: #fff;
    border: none;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
    outline: 1px solid #fff;
    margin-top: 0.5rem;

    transition: all 1s ease;
}

.form-send-button button:hover {
    background: #fff;
    color: #222222;
    outline: 1px solid #222222;
}


.recaptcha {
    padding-top: 3rem; 
    margin: 0rem auto
}

#recaptcha-verification-text, 
#valid-email-verification-text, 
#fill-fields-verification-text, 
#invalid-recaptcha {
    color: #d9534f;
    display:none;
    font-size: 14px;
    font-style: italic;
}

#email-sent {
    color: #5cb85c;
    display:none;
    font-size: 14px;
    font-style: italic;
}

#contact-form-interested {
    width: 80%;
    text-align: left;
}

option:hover {
    background-color: #404040;
}


@media screen and (max-width: 700px) {
   
    .contact-us-form {
        width: 80%;
    }

    .contact-us-form input, .contact-us-form textarea{
        width: 80%;
    }
    
}