.studio-people {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    margin: 0 4rem;

    padding-top: 2rem;
    padding-bottom: 7rem;

}

.studio-people-card {
    width: 32%;
    /* margin-right: auto;
    margin-left: auto; */
    position: relative;
}

.studio-people-card-body p {
    margin-bottom: 0rem;
    position: absolute;
    top: 85%;
    left: 2%;
    font-size: small;
    color: #fff;
}

.studio-people-card-img {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.studio-people-card-img img {
    width: 90%;
    object-fit: contain;
}

.studio-animation {
    animation: fadeInUp; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1.5s; 
    animation-delay: .5s;
}

@media only screen and (max-width: 640px) {
    .studio-people {
        display: block;
    }

    .studio-people-card {
        width: 100%;
        
        margin: 0rem auto;
        margin-bottom: 5rem;
    }

    .studio-people-card-body p {
    
        top: 65%;
    
    }
  }

