.homepage-footer-container {
    text-align: center;
    background-color: transparent;
    

    width: 100%;

    position: absolute;

    top: 92%;

    z-index: 1;
}

.homepage-footer-container-social a {
    margin: .5rem;
}

.homepage-footer-container-social a img{
    width: 2%;
}

@media only screen and (max-width: 640px) {

    
    .homepage-footer-container-social a img{
        width: 5%;
    }

    .homepage-footer-container {
        text-align: center;
        background-color: transparent;
    
        width: 100%;
    
        position: absolute;
    
        top: 85%;
    
        z-index: 1;
    }

}