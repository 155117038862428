.hero-container {
  width: 100%;
  height: 100vh ;
  position: relative;
  overflow-x: hidden;
}

.slide-container,
.each-fade {
  height: 100vh;
}

.image-container img{
  width: 110vw;
  height: 110vh;
  position: absolute;
  top: 0;
  left: 0;
}

#homepage-img-01 {
  animation-name: slide;
  animation-iteration-count: infinite;
  animation-duration: 10s;
}

#homepage-img-02 {
  animation-name: slide;
  animation-iteration-count: infinite;
  animation-duration: 10s;
  animation-delay:3s;
}

#homepage-img-03 {
  animation-name: slide;
  animation-iteration-count: infinite;
  animation-duration: 10s;
  animation-delay: 6s;
}

.each-fade h2 {
  position: absolute;
  top: 55%;
  width: 100%;
  color: #fff;
  text-align: center;

  letter-spacing: .1rem;

  font-weight: lighter;

  animation: fadeInUp; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 1.5s; 

}

.each-fade h4 {
  position: absolute;
  top: 70%;
  width: 100%;
  color: #fff;
  text-align: center;

  font-weight: bolder;

  animation: fadeInUp; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 1.5s; 
}

.indicators {
  position: absolute;
  top: 80%;
  width: 100%;

  z-index: 10;
}

.react-slideshow-container + ul.indicators li {
  margin: 0rem .2rem;
}

.react-slideshow-container + ul.indicators .each-slideshow-indicator::before{
  background: #fff !important;
}

.react-slideshow-container + ul.indicators {
  padding-left: 0px;
}

@keyframes slide {
  0% {
    left: 0;
  }

  100% {
    left: -5%;
  }
}


@media only screen and (max-width: 640px) {
  .carousel-caption {
    width: 100%;
  }

  .hero-container {
    height: 100vh;
    position: relative;
  }

  .each-fade h2 {
    font-size: large;
  
  }

  .image-container img{
    width: 140vw;
    
  }

}
