.footer-container {
  display: flex;
  flex-direction: column;
  height: 70vh;
  padding-top: 2rem;
  margin: 0 2rem;
}

.footer-container-row-img {
  text-align: center;
  width: 10%;
  margin: 0 auto;
}

.footer-container-row-img img{
  width: 100%;
}

.footer-container-row{
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  margin:.5rem 0rem;
  padding-top: 3rem;
}

.footer-container-row-col-title {
  text-align: center;
  font-size: large;
  margin-bottom: 1rem !important;
}

#footer-container-row-col-1,
#footer-container-row-col-2 {
  text-align: center;
}

#footer-container-row-col-4 {
  display: none;
}

.footer-container-row-col p {
  margin-bottom: 0rem;
}


.footer-container-row-menu {
  display: flex;
  flex-direction: column;
}

.footer-container-row-menu a{
  color: #000;
  text-align: center;
  text-decoration: none;
  margin-bottom: .5rem;
}

.footer-container-row-social {
  padding: 3rem 0rem;
  text-align: center;
}

.footer-container-row-social img{
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}


@media only screen and (max-width: 640px) {

  .footer-container {
    padding-top: 2rem;
    min-height: 70vh;
  }

  .footer-container-row-img {
    width: 50%;
    margin-bottom: 1rem;
  }
    
  .footer-container-row {
    display: block;
    text-align: center;
    margin: 0rem;
  }

  .footer-container-row-col {
    width: 100%;
  }

  #footer-container-row-col-1,
  #footer-container-row-col-2,
  #footer-container-row-col-3 {
    text-align: center;
    margin-bottom: 2rem;
  }

  #footer-container-row-col-2 {
    display: none;
  }

  #footer-container-row-col-4 {
    display: block;
  }

  .footer-container-row-social {
    padding: .5rem 0rem;
  }
}