.services-section {
   padding: 7rem 4rem 5rem 4rem; 
   /* height: 100vh; */
   position: relative;

   min-height: fit-content;
}

.services-section p {
    text-align: justify;
    font-size: small;

    transition: all 1s ease;
    
}

.services-animation {
    animation: fadeInUp; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1.5s; 
    animation-delay: .5s;
}

@media only screen and (max-width: 640px) {
    .services-section {     
        height: fit-content;
     }

}