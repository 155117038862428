.contact-section-container {
    display: flex;

    text-align: center;
    padding: 10rem 2rem;

    justify-content: space-evenly;

    ;
}

.contact-section-container-card {
    display: flex;
    flex-direction: column;
}

.contact-section-container-card a {
    color: black;
    text-decoration: none;
    margin-bottom: 1rem

}

.contact-section-container-card a:hover {
    font-weight: bold;
}

.contact-section-container-card-title {
    font-size: large;
}

.contact-section-container-card-p {
    font-size: small;
    margin-bottom: 0rem !important;
}

@media only screen and (max-width: 640px) {
    .contact-section-container {
        display: block;
    }

    .contact-section-container-card{
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}