.prefooter-container {
    text-align: center;
    padding-top: 8rem;
    padding-bottom: 2rem;

    min-height: 20vh;
}

@media only screen and (max-width: 640px) {

    .prefooter-container {
        padding-bottom: 0rem;
        min-height: 20vh;
    }
  }