.projects-gallery-container {
    padding-top: 1rem;
}

.projects-gallery-container-two-images-row {
    display: flex;
    justify-content: space-around;
    margin: 0rem 4rem;
    margin-bottom: 1rem;
    height: 60vh;
}


.projects-gallery-container-two-images-row-img {
    width: 49%;
    position: relative;
    background-color: #404040;
    height: 60vh;
    object-fit: contain;
}


.projects-gallery-container-two-images-row-img img{
    width: 100%;
    height: 60vh;
}

.projects-gallery-container-two-images-row-img-card-body {
    position: absolute;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    text-align: center;
    text-transform: uppercase;
}

.projects-gallery-container-two-images-row-img:hover 
.projects-gallery-container-two-images-row-img-card-body {
    display: block;
    color: #fff;
}

.projects-gallery-container-two-images-row-img:hover 
.projects-gallery-container-two-images-row-img-card img {
    opacity: 0.6;
}

.projects-gallery-container-two-images-row-img-card:hover {
    cursor: pointer;
}

@media only screen and (max-width: 640px) {

    .projects-gallery-container-two-images-row {
        display: block;
        height: fit-content;
        margin: 0rem 0.5rem;
    }

    .projects-gallery-container-two-images-row-img {
        width: 100%;
        background-color: #404040;
    
        height: 50vh;

        margin-bottom: 1rem;
    }

    .projects-gallery-container-two-images-row-img img{
        height: 50vh;
    }
}