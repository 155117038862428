@font-face {
    font-family: "CenturyGothic";
    src: local("CenturyGothic"),
     url("./fonts/CenturyGothic/GOTHIC.TTF") format("truetype");
}

*:focus { 
    outline: 0 !important;
    box-shadow: none;
  }

/* #root {
    font-family: 'FuturaGreek-Medium' !important;
} */