.homepage-navbar {
    display: flex;
    background-color: transparent;
    padding-top: 2.5rem;
    padding-bottom: .5rem;
}

.homepage-navbar-links,
.homepage-navbar-links-menu,
.homepage-navbar-translation-menu {
    width: 33.3%;
}

.homepage-navbar-links-logo img {
    width: 20%;

    margin-left: 2rem;
}

.homepage-navbar-links-menu {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
}

.homepage-navbar-links-menu-container {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    

}

.homepage-navbar-links-menu-container a{
    margin: 0 1rem;
    color: #fff;
    text-decoration: none;

    font-size: small;

    letter-spacing: .1rem;
}

.homepage-navbar-translation-menu {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    text-align: right;

    padding-right: 2rem;

    font-size: small;
}


.homepage-navbar-translation-menu-btn {
    background-color: transparent;
    border: none;
    color: #fff;
    
}

/* HOVER EFFECT TRY */


.homepage-navbar-links-menu-container a {
    text-decoration: none;
    position: relative;
    display: block;
    
    transition: color 0.1s, background-color 0.1s;
  }
  .homepage-navbar-links-menu-container a:hover {
    color: #fff;
  }
  .homepage-navbar-links-menu-container a:focus, a:active {
    color: #fff;
  }
  
  .homepage-navbar-links-menu-container a::before {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    height: 1px;
    width: 100%;
    background-color: #fff;
    -webkit-transform-origin: center top;
            transform-origin: center top;
    -webkit-transform: scale(0, 1);
            transform: scale(0, 1);
    transition: color 0.1s, -webkit-transform 0.2s ease-out;
    transition: color 0.1s, transform 0.2s ease-out;
    transition: color 0.1s, transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  }
  
  .homepage-navbar-links-menu-container a:active::before {
    background-color: #fff;
  }
  
  .homepage-navbar-links-menu-container a:hover::before,
  .homepage-navbar-links-menu-container a:focus::before {
    -webkit-transform-origin: center top;
            transform-origin: center top;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }


/* --------------------- */


@media only screen and (max-width: 640px) {
    .homepage-navbar {
        display: flex;
        justify-content: center;
        padding-top: .5rem;


    }

    .homepage-navbar-links,
    .homepage-navbar-links-menu,
    .homepage-navbar-translation-menu {
        width: 100%;
    }

    .homepage-navbar-links-logo {
        width: 100%;
        text-align: center;
        padding-top: .5rem;
    }

    .homepage-navbar-links-logo img{
        width: 30%;
        margin-left: -2rem;
    }

    .homepage-navbar-links-menu-container {
        margin: 0 auto;
        padding-bottom: 1rem;
    }

    .homepage-navbar-links-menu,
    .homepage-navbar-translation-menu {
        display: none;
    }

    .homepage-navbar-dropdown {
        display: block;

    }

    .dropdown-menu {
        margin-right: .5rem;
    }

    .navbar-dropdown a,
    .dropdown-menu a{
        color: #000;
        text-decoration: none;
    }

    .dropdown-item:active{
        color: #000;
        background-color: #fff;
        text-decoration: none;
    }

    .dropdown-toggle::after {
        display: none;
    }

}
